import {useEffect, useState} from "react";
import {getUserBadges} from "../services/user";
import {useTranslation} from "react-i18next";
import ClayIcon from "@clayui/icon";
import ClayLayout from '@clayui/layout';

const UserDashboardBadges = ({coursesLandingPage}) => {

    const [userBadges, setUsersBadges] = useState(null);

    const {t, i18n: {changeLanguage, language}} = useTranslation();

    useEffect(() => {

        getUserBadges().then((badges) => {
            setUsersBadges(badges);
        })

    }, []);

    return <>
        {userBadges && userBadges.length > 0 && (
            <ClayLayout.ContainerFluid>
                <ClayLayout.Row>
                {userBadges.map((badge, index) =>
                    <ClayLayout.Col size={4} className="d-flex flex-column">
                        <div className="badge-card" key={index}>
                            <div className="badge-img-container mb-3">
                                <img
                                    src={badge.image}
                                    alt={badge.title}
                                ></img>
                            </div>
                            <h3>{badge.title}</h3>
                            <p>{badge.year}</p>
                        </div>
                    </ClayLayout.Col>
                )}
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
        )}

        {userBadges && userBadges.length == 0 && (
            <div className="empty-section-card">
                <h3>{t('dashboard-label-no-badges-header')}</h3>
                <p>
                    {t('dashboard-label-no-badges-body')}
                </p>
                <a href={coursesLandingPage} className="card-cta-button">
                    {t('explore-courses')}
                    <ClayIcon className="ml-2" symbol="order-arrow-right"/>
                </a>
            </div>
        )}
    </>

}

export default UserDashboardBadges;
