import {getServerUrl, oAuthRequest} from "../utils/request";
import {config} from "../utils/constants";

export const getAssetDuration = (assetId,assetType) =>{

    const serviceBaseAddress = getServerUrl();

    let methodUrl = ""

    switch (assetType){
        case "learningPath":{
            methodUrl="learning-path";
            break;
        }
        case "learningPathStep":{
            methodUrl="learning-path-step";
            break;
        }
        case "course": {
            methodUrl = "course";
            break;
        }
    }

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.navigationMenuEndPoint}${methodUrl}/${assetId}/breakdown`,
        method:'get',
    });

}
