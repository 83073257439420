import {forwardRef, useCallback, useEffect, useState} from "react";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import {getNavigationMenu} from "../services/menu";
import ClayPanel from '@clayui/panel';
import ClaySticker from "@clayui/sticker";
import {Text} from '@clayui/core';
import ClayIcon from "@clayui/icon";
import {getProgress} from "../services/progress";
import ClayList from "@clayui/list";


const ArticleNavigation = forwardRef((props, ref) => {

    const {selector, containerId} = props;

    const [anchorElements, setAnchorElements] = useState(null);
    const [selectedAnchorId, setSelectedAnchorId] = useState("");

    const scrollToPageElement = useCallback((elementId) => {

        const element = document.getElementById(elementId);
        const topPosition = element.offsetTop;

        window.scrollTo({
            top: topPosition,
            behavior: 'smooth'
        });

        setSelectedAnchorId(elementId);

    }, [anchorElements])

    const getPageAnchors = () => {

        if (containerId && containerId.length > 0) {

            const container = document.getElementById(containerId);

            const filteredElements = Array.from(container.querySelectorAll(selector))
                .filter(element => element.id !== null && element.id !== "");

            setAnchorElements(filteredElements);

        } else {

            const filteredElements = Array.from(document.getElementsByTagName(selector))
                .filter(element => element.id !== null && element.id !== "");

            setAnchorElements(filteredElements);

        }

    }

    useEffect(() => {

        if (anchorElements) {

            const observer = new IntersectionObserver((entries) => {

                const visibleEntries = entries.filter(entry => entry.isIntersecting);

                if (visibleEntries.length > 0) {

                    const closestElement = visibleEntries.reduce((closest, entry) => {

                        const rect = entry.boundingClientRect;

                        const offset = rect.top >= 0 ? rect.top : Infinity;

                        return offset < closest.offset ? {element: entry, offset} : closest;

                    }, {element: null, offset: Infinity}).element;

                    if (closestElement) {
                        setSelectedAnchorId(closestElement.target.id);
                    }
                }
            }, {
                threshold: [0.25, 0.50, 0.75, 1],
            });

            anchorElements.forEach(element => {
                observer.observe(element);
            });

            return () => {

                anchorElements.forEach(element => {
                    observer.unobserve(element);
                });

            };
        }


    }, [anchorElements])

    useEffect(() => {

        getPageAnchors();

    }, []);

    return (
        <div
            className={anchorElements && anchorElements.length > 0 ? "article-navigation show" : "article-navigation hide"}>
            {anchorElements && (
                <ClayList>
                    {anchorElements.map((anchorElement) =>

                        <ClayList.Item
                            className={`border-0 article-nav-element ${selectedAnchorId == anchorElement.id ? "active" : ""}`}
                            flex>
                            <ClayList.ItemText style={{cursor: "pointer"}} onClick={() => {
                                scrollToPageElement(anchorElement.id)
                            }}>
                                <Text size={4} weight={"bold"}>{anchorElement.innerText}</Text>
                            </ClayList.ItemText>
                        </ClayList.Item>
                    )}
                </ClayList>
            )}
        </div>);
});

export default ArticleNavigation;
