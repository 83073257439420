/*global Liferay*/
import {forwardRef, useEffect, useState} from "react";
import {getAttachmentsByLessonId} from "../services/course";
import ClayCard from '@clayui/card';
import ClayIcon from '@clayui/icon';
import ClayLayout from '@clayui/layout';
import ClayLink from '@clayui/link';
import ClayEmptyState from "@clayui/empty-state";
import {useTranslation} from "react-i18next";

const LessonAttachmentsView = forwardRef((props, ref) => {

    const {lessonId} = props;
    const {t, i18n: {changeLanguage, language}} = useTranslation();

    const [isLoading,setIsLoading] = useState(false);
    const [attachments, setAttachments] = useState(null);

    const loadAttachments = ()=>{

        getAttachmentsByLessonId(lessonId).then(result=>{

            setAttachments(result.items);

            setIsLoading(false);

        },error=>{

            setAttachments([]);
            setIsLoading(false);

        }).catch(error=>{

            setAttachments([]);
            setIsLoading(false);

        })

    }

    useEffect(() => {

        loadAttachments();

    }, [lessonId]);

    return (
            <>
                {attachments && attachments.length > 0 && !isLoading && (

                    <ClayLayout.ContainerFluid size={false}>
                        <ClayLayout.Row justify="start">
                            {attachments.map(attachment =>  (
                                <ClayLayout.Col size={3} key={attachment.id}>
                                    <ClayCard displayType="file">
                                        <ClayCard.AspectRatio className="card-item-first">
                                            <div className="aspect-ratio-item aspect-ratio-item-center-middle aspect-ratio-item-fluid card-type-asset-icon">
                                                <ClayIcon symbol="documents-and-media" />
                                            </div>
                                        </ClayCard.AspectRatio>
                                        <ClayCard.Body>
                                            <ClayCard.Row>
                                                <div className="autofit-col autofit-col-expand">
                                                    <section className="autofit-section">
                                                        <ClayCard.Description displayType="title">
                                                            <ClayLink decoration="none" href={attachment.file.link.href}>
                                                                {attachment.title}
                                                            </ClayLink>
                                                        </ClayCard.Description>
                                                        <ClayCard.Description displayType="subtitle">
                                                            {attachment.description}
                                                        </ClayCard.Description>
                                                    </section>
                                                </div>
                                            </ClayCard.Row>
                                        </ClayCard.Body>
                                    </ClayCard>
                                </ClayLayout.Col>
                            ))}
                        </ClayLayout.Row>
                    </ClayLayout.ContainerFluid>

                )}

                { ( !attachments || (attachments && attachments.length === 0) ) && !isLoading && (
                    <ClayEmptyState
                        title={t('no-attachments')}
                        imgSrc={`${Liferay.ThemeDisplay.getPathThemeImages()}/states/search_state.gif`}
                        description={t('lesson-has-no-attachments')}
                    />
                )}

            </>
    );

});


export default LessonAttachmentsView;
