import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import enJSON from './locale/en.json'
import arJSON from './locale/ar.json'
import esJSON from './locale/es.json'
import {getCurrentLanguageKey} from "./utils/util";


i18n.use(initReactI18next).init({
    resources: {
        en: { ...enJSON },
        ar: { ...arJSON },
        es: { ...esJSON },
    },
    lng: getCurrentLanguageKey(),
});
