import React from 'react';
import {useTranslation} from "react-i18next";
import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
//import '../styles/QuizResult.css'

const QuizResultSheet = ({scoreSheetOverall, badgePageUrl, educationPageUrl,handleReset}) => {

    const {
        exerciseId,
        passed,
        passingScore,
        totalScore,
        scoreSheet,
        title,
        totalPassedQuestions,
        totalQuestions,
        badges
    } = scoreSheetOverall;

    const {t, i18n: {changeLanguage, language}} = useTranslation();


    return (
        <>
            {scoreSheetOverall && (
                <div className='container-quiz-result'>

                    <div className="quiz-result-sheet">
                        <div className="content-result">
                            <h3>{
                                passed ?
                                    `${t('congratulations')} 🎉` :
                                    `${t('failed-title')} \u{1F61E}`
                            } </h3>
                            <div className='text-quiz-result'>
                                <p>
                                    {
                                        passed ?
                                            t('congratulations-text', {courseTitle: title}) :
                                            t('failed-message', {passingScore: passingScore})
                                    }
                                </p>
                            </div>
                        </div>

                        <div className='content-score'>
                            <p>{t("your-score")} </p>
                            <div className='ponctuation'>
                                <span className={"d-flex"}>
                                    <span className={"d-flex"}>
                                <h3 className={"m-auto"}>
                                     {t('score-break-down', {
                                         totalCorrect: totalPassedQuestions,
                                         totalCount: totalQuestions,
                                         score: totalScore
                                     })}
                                </h3>
                            </span>
                                    {passed && (
                                        <span className='pass-tag d-flex'>
                                <span className={"m-auto"}>
                                    <span className='circle'>
                                    <span>✔</span>
                                </span>
                                <span>
                                    {t('pass')}
                                </span>
                                </span>
                            </span>
                                    )}
                                </span>
                            </div>
                            {!passed && (
                                <div className="quiz-retake my-2">
                                    <ClayButton onClick={handleReset}>

                                        {t('try-again')}

                                        <span className="inline-item inline-item-before mx-2">
                                            <ClayIcon symbol="reload"/>
                                        </span>

                                    </ClayButton>
                                </div>
                            )}
                        </div>
                    </div>
                    {passed && (
                        <div className='container-badges'>
                            <div className='badges-content'>


                                {badges && badges.length > 0 && (
                                    <div className='rectangle-bagdes'>
                                        <img alt={badges[0].title} src={badges[0].badge.link.href} alt='badge' />
                                    </div>
                                )}

                                <div className='see-badges'>
                                    <a href={badgePageUrl}>
                                    {t('see-my-badges')} →
                                    </a>
                                </div>

                            </div>

                            <a href={educationPageUrl}>
                                {t('return-to-education-home-page')}
                            </a>
                        </div>
                    )}
                </div>
            )}
        </>
    );

};

export default QuizResultSheet;
