/* global Liferay */


import {forwardRef, useEffect, useState} from "react";
import ClayIcon from "@clayui/icon";
import {getCookie, getNextLesson, getPreviousLesson} from "../utils/util";


const LessonNavigation = forwardRef((props, ref) => {


    const {upNextLabel,linkPrefix} = props;

    const [nextLesson, setNextLesson] = useState(null);
    const [prevLesson, setPrevLesson] = useState(null);

    useEffect(() => {

        Liferay.once('lessonNavigationUpdated', (event) => {

            setNextLesson(getNextLesson())
            setPrevLesson(getPreviousLesson());

        });

    }, []);

    return (
        <div>
            <div>
                {nextLesson && nextLesson.id && (
                    <a href={`${linkPrefix && linkPrefix.length > 0?linkPrefix:'/l/'}${nextLesson.id}`}>
                        <div className="course-nav-bottom__banner d-flex">
                            <div className="banner-options d-flex">
                                <div className="banner-next-container mr-1">
                                    {upNextLabel}
                                </div>

                                <div className="banner-title">
                                    {nextLesson.title}
                                </div>
                            </div>

                            <div className="banner-icon">
                                <ClayIcon symbol="order-arrow-right"></ClayIcon>
                            </div>
                        </div>
                    </a>
                )}
            </div>

            {prevLesson && (<>

                <div className="course-nav-bottom__menu d-flex">
                    <div className="menu-previous-lesson d-flex">
                        <a href={`${linkPrefix && linkPrefix.length > 0?linkPrefix:'/l/'}${prevLesson.id}`}>
                            <div className="previous-lesson-icon">
                                <ClayIcon symbol="order-arrow-left"></ClayIcon>
                            </div>
                        </a>

                        <div className="previous-lesson-title">
                            {prevLesson.title}
                        </div>
                    </div>

                </div>

            </>)}

        </div>
    );

});

export default LessonNavigation;
