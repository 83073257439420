import {getServerUrl, oAuthRequest} from "../utils/request";
import {config} from "../utils/constants";

export const getStepInfo = (stepId,stepType) => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.utilsEndPoint}step/${stepId}/${stepType}`,
        method: 'get',
    });

}
