import {getServerUrl, oAuthRequest} from "../utils/request";
import {config} from "../utils/constants";


export const getCourseFirstLesson = (courseId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.navigationMenuEndPoint}lessonOne/${courseId}`,
        method:'get'
    });

}

export const getLearningPathBreakdown = (learningPathId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.navigationMenuEndPoint}learning-path/${learningPathId}/breakdown`,
        method:'get'
    });

}

export const getLearningPathStepBreakdown = (learningPathStepId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.navigationMenuEndPoint}learning-path-step/${learningPathStepId}/breakdown`,
        method:'get'
    });

}

export const getNavigationMenu = (assetId,assetType,navigationMenuType) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.navigationMenuEndPoint}${assetId}/${assetType}/${navigationMenuType}`,
        method:'get'
    });

}
