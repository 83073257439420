import {forwardRef, Fragment, useCallback, useEffect, useState} from "react";
import {getCurrentLanguageKey, getDPTAssetId, getSignInPath, isSignedIn, showError, showSuccess} from "../utils/util";
import {
    enrollToCourse,
    enrollToLearningPath,
    getEnrollmentsByCourseId,
    getEnrollmentsByLearningPathId
} from "../services/course";
import ClayButton from '@clayui/button';
import ClayIcon from '@clayui/icon';
import {useTranslation} from "react-i18next";
import ClayLabel from '@clayui/label';
import ClayLoadingIndicator from "@clayui/loading-indicator";
import SignIn from "./SignIn";


const Enroll = forwardRef((props, ref) => {

    const {courseId,learningPathId,children} = props;

    const {t, i18n: {changeLanguage, language}} = useTranslation();

    const [assetId, setAssetId] = useState(null);
    const [assetType, setAssetType] = useState(null);
    const [enrollment, setEnrollment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const handleEnroll = useCallback(() => {

        switch (assetType) {

            case "course":

                setIsLoading(true);
                enrollToCourse(assetId).then(result => {

                    setEnrollment(result);
                    showSuccess(t('success'), t('enroll-course-success'));
                    setIsLoading(false);
                }, error => {

                    console.log(error);
                    showError(t('error'), t('enroll-course-error'));

                }).catch(error => {
                    console.log(error);
                    showError(t('error'), t('enroll-course-error'));

                }).finally(() => {
                    setIsLoading(false);
                });
                break;

            case "learning_path":

                setIsLoading(true);
                enrollToLearningPath(assetId).then(result => {

                    setEnrollment(result);
                    showSuccess(t('success'), t('enroll-learning-path-success'));
                    setIsLoading(false);
                }, error => {

                    console.log(error);
                    showError(t('error'), t('enroll-laerning-path-error'));

                }).catch(error => {
                    console.log(error);
                    showError(t('error'), t('enroll-learning-path-error'));

                }).finally(() => {
                    setIsLoading(false);
                });
                break;
        }

    }, [assetId,assetType])

    useEffect(() => {

        switch (assetType){
            case "course":
                getEnrollmentsByCourseId(assetId).then(result => {

                    if (result.totalCount === 0) {

                        setIsLoading(false);

                    } else {

                        setEnrollment(result.items[0]);
                    }

                }, errorr => {
                    setIsLoading(false);

                }).catch(error => {
                    setIsLoading(false);
                })
                break;
            case "learning_path":
                getEnrollmentsByLearningPathId(assetId).then(result => {

                    if (result.totalCount === 0) {

                        setIsLoading(false);

                    } else {

                        setEnrollment(result.items[0]);
                    }

                }, errorr => {
                    setIsLoading(false);

                }).catch(error => {
                    setIsLoading(false);
                })
                break;

        }

    }, [assetId,assetType])

    useEffect(() => {

        changeLanguage(getCurrentLanguageKey());

        if (courseId && courseId.length > 0) {

            setAssetId(courseId);
            setAssetType('course');

        } else if (learningPathId && learningPathId.toString().length > 0) {

            setAssetId(learningPathId);
            setAssetType('learning_path');

        }else {

            setAssetId(getDPTAssetId());

        }


    }, []);

    return (
            <SignIn>
                {isLoading && (
                    <ClayLoadingIndicator displayType="secondary" size="sm"/>
                )}

                {!enrollment && (
                    <ClayButton size={"sm"} displayType={'secondary'} onClick={handleEnroll}>
                <span className="inline-item inline-item-before">
                    <ClayIcon symbol="circle"/>
                </span>
                        {t('enroll')}
                    </ClayButton>
                )}

                {enrollment && (
                    <ClayLabel displayType={'success'}>
                        <div className="d-flex p-2 ">
                            <div className="inline-item inline-item-before m-auto">
                                <ClayIcon symbol="circle"/>
                            </div>
                            <div className="m-auto px-1">
                                {t(assetType === "course"?'enrolled-course':'enrolled-learning-path')}
                            </div>
                        </div>
                    </ClayLabel>
                )}
                {children && (
                    <div className="bg-light p-2">
                        <label className="text-3">Mapped Configuration</label>
                        {children.map(child => child)}
                    </div>
                )}


            </SignIn>
    );


});

export default Enroll;
