import ClayProgressBar from "@clayui/progress-bar";
import ClayIcon from "@clayui/icon";
import {useTranslation} from "react-i18next";


const CompletedCourse = ({course}) => {

    const {t, i18n: {changeLanguage, language}} = useTranslation();


    const getCourseProgress = () =>{
        return (course.totalCompletedElements / course.totalElements * 100).toFixed(0);
    }

    const getFirstLesson = () =>{

        if (course.breakdown && course.breakdown.length > 0){

            if (course.breakdown[0] && course.breakdown[0].children.length > 0){
                return course.breakdown[0].children[0].id;
            }
        }

    }

    return (<a
        href={`/l/${getFirstLesson()}`}
        key={course.id}
        className="section-card"
    >
        <div>
            <h3>{course.title}</h3>
            <p>{course.description}</p>
            <p className="completed-tag">
                Completed
                <ClayIcon className="ml-2" symbol="check"/>
            </p>
        </div>
    </a>);
}

export default CompletedCourse;
