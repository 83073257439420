import {forwardRef, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {convertMinutesToDuration} from "../utils/util";
import {getAssetDuration} from "../services/duration";
import ClayLoadingIndicator from "@clayui/loading-indicator";

const Duration = forwardRef((props, ref) => {

    const {assetId,assetType,format} = props;

    const [duration, setDuration] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const {t, i18n: {changeLanguage, language}} = useTranslation();

    useEffect(() => {

        setIsLoading(true);

        getAssetDuration(assetId,assetType).then(result => {

            setDuration(convertMinutesToDuration(result.totalDuration,format));

            setIsLoading(false);

        })

    }, []);

    return (<>
        {isLoading && (
            <span style={{display: 'inline-block'}}>
                <ClayLoadingIndicator displayType="secondary" size="sm"/>
            </span>

        )}
        {!isLoading && duration && (
            <span className="duration">{duration}</span>
        )}
    </>)

});

export default Duration;
