import {getCookie, getNextLesson, getNextLessonId, getPreviousLesson, getPreviousLessonId, setCookie} from "./util";
import {
    getCourseByLessonId,
    enrollToCourse,
    enrollToLearningPath,
    getCourseByQuizId,
    getCourseByAssetTypeAssetId
} from "../services/course";
import {getProgress, postProgress} from "../services/progress";
import {getAssetDuration} from "../services/duration";
import {
    getCourseFirstLesson,
    getLearningPathBreakdown,
    getLearningPathStepBreakdown,
    getNavigationMenu
} from "../services/menu";
import {getStepInfo} from "../services/learning-path-step";
import {getUserBadges, getUserEnrollmentByAssetId, getUserEnrollmentById, getUserEnrollments} from "../services/user";


window.LiferayLMS = {};
window.LiferayLMS.enrollToCourseAsync = enrollToCourse;
window.LiferayLMS.enrollToLearningPathAsync = enrollToLearningPath;
window.LiferayLMS.getAssetDurationAsync = getAssetDuration;
window.LiferayLMS.getCookie = getCookie;
window.LiferayLMS.getCourseFirstLessonAsync = getCourseFirstLesson;
window.LiferayLMS.getNavigationMenuAsync = getNavigationMenu;
window.LiferayLMS.getNextLessonId = getNextLessonId;
window.LiferayLMS.getProgressAsync = getProgress;
window.LiferayLMS.getPreviousLessonId = getPreviousLessonId;
window.LiferayLMS.getNextLessonId = getNextLessonId;
window.LiferayLMS.getNextLesson= getNextLesson;
window.LiferayLMS.getPreviousLesson = getPreviousLesson;
window.LiferayLMS.postProgressAsync = postProgress;
window.LiferayLMS.setCookie = setCookie;
window.LiferayLMS.getCourseByLessonIdAsync = getCourseByLessonId;
window.LiferayLMS.getCourseByQuizIdAsync = getCourseByQuizId;
window.LiferayLMS.getCourseByAssetTypeAssetIdAsync = getCourseByAssetTypeAssetId;
window.LiferayLMS.getStepInfoAsync = getStepInfo;
window.LiferayLMS.getUserEnrollmentsAsync = getUserEnrollments;
window.LiferayLMS.getUserEnrollmentByIdAsync = getUserEnrollmentById;
window.LiferayLMS.getLearningPathBreakdownAsync = getLearningPathBreakdown;
window.LiferayLMS.getLearningPathStepBreakdownAsync = getLearningPathStepBreakdown;
window.LiferayLMS.getUserEnrollmentByAssetIdAsync = getUserEnrollmentByAssetId;
window.LiferayLMS.getUserBadgesAsync = getUserBadges;

