import {forwardRef, useEffect, useState} from "react";
import {loadObjectDefinitions} from "../services/object-definitions";
import ClayIcon from "@clayui/icon";
import ClayCard from "@clayui/card";
import {Text} from '@clayui/core';

import {getAdminPanelUrl, getCurrentLanguage, getCurrentLanguageKey, getCurrentSiteId} from "../utils/util";
import {useTranslation} from "react-i18next";

const MENU_CONFIG = {
    T4T14_LESSON:{
        icon:"book",
        position:3,
        visible:true,
    },
    T4T14_ENROLLMENTS:{
        icon:"check-circle",
        position:5,
        visible:false,
    },T4T14_MODULE:{
        icon:"books",
        position:2,
        visible:true,
    },T4T14_COURSE:{
        icon:"bookmarks-full",
        position:1,
        visible:true,
    },T4T14_ATTACHMENT:{
        icon:"paperclip",
        position:4,
        visible:true,
    },
    T4T13_LEARNING_PATH:{
        icon:"workflow",
        position:5,
        visible:true,
    },
    T4T13_ARTICLE:{
        icon:"document",
        position:7,
        visible:true,
    },
    T4T14_EXTERNAL_MEDIA:{
        icon:"document-multimedia",
        position:6,
        visible:true,
    },
    T4T13_LEARNING_PATH_STEP:{
        icon:"breadcrumb",
        position:6,
        visible:true,
    },
    T4T14_QUIZ:{
        icon:"mark-as-question",
        position:6,
        visible:true,
    },
    T4T14_BADGE:{
        icon:"content-shield",
        position:6,
        visible:true,
    }

}

const LMSMenu = forwardRef((props, ref) => {

    const { t, i18n: {changeLanguage, language} } = useTranslation();

    const [objectDefinitions, setObjectDefinitions] = useState(null);
    const [currentLanguage, setCurrentLanguage] = useState(language)

    const loadMenu = () => {

        loadObjectDefinitions().then(items => {

            setObjectDefinitions((items.map(item=> ({
                ...item,
                ...MENU_CONFIG[item.erc]
            }))).sort((a,b)=>a.position-b.position));

        })
    }

    useEffect(() => {

        setCurrentLanguage(getCurrentLanguageKey());

        loadMenu()

    }, []);

    return (

        <div className="container my-4">
            <div className="container my-4">
                <Text size={11}>{t('lr-lms')}</Text>
            </div>
            <div className="row">
                {objectDefinitions && objectDefinitions.length > 0 && (
                    objectDefinitions.map((item) => (
                        <>
                            {
                                item.visible && (
                                    <div className="col-md-3">
                                        <a href={`${getAdminPanelUrl()}?p_p_id=com_liferay_object_web_internal_object_definitions_portlet_ObjectDefinitionsPortlet_${item.id}&p_p_lifecycle=0&p_p_state=maximized&p_v_l_s_g_id=${getCurrentSiteId()}&_com_liferay_object_web_internal_object_definitions_portlet_ObjectDefinitionsPortlet_${item.id}_objectDefinitionId=${item.id}`}>
                                            <ClayCard displayType="file">
                                                <ClayCard.AspectRatio className="card-item-first">
                                                    <div
                                                        className="aspect-ratio-item aspect-ratio-item-center-middle aspect-ratio-item-fluid card-type-asset-icon">
                                                        <ClayIcon symbol={item.icon}/>
                                                    </div>
                                                </ClayCard.AspectRatio>
                                                <ClayCard.Body>
                                                    <ClayCard.Row>
                                                        <div className="autofit-col autofit-col-expand">
                                                            <section className="autofit-section">
                                                                <ClayCard.Description displayType="title">
                                                                    {item.title[getCurrentLanguage()]}
                                                                </ClayCard.Description>
                                                            </section>
                                                        </div>
                                                    </ClayCard.Row>
                                                </ClayCard.Body>
                                            </ClayCard>
                                        </a>
                                    </div>
                                )
                            }
                        </>

                    ))
                )}
            </div>
        </div>

    )

});

export default LMSMenu;
