/*global Liferay*/
import {useEffect, useState} from "react";
import {getUserEnrollments} from "../services/user";
import ClayIcon from "@clayui/icon";
import CompletedCourse from "./CompletedCourse";
import ClayLayout from '@clayui/layout';
import UncompletedCourse from "./UncompletedCourse";
import {useTranslation} from "react-i18next";

const UserDashboardCoursesList = ({status,coursesLandingPage}) => {

    const [userEnrollments, setUserEnrollments] = useState(null);

    const {t, i18n: {changeLanguage, language}} = useTranslation();


    useEffect(() => {

        getUserEnrollments().then((enrollments) => {

            if (enrollments && enrollments.length > 0) {

                setUserEnrollments(enrollments.filter(enrollment => enrollment.completed == status && enrollment.type === "course"));
            }

        })

    }, []);

    return (
        <div className={"user-dashboard"}>
            {userEnrollments && userEnrollments.length > 0 && (
                <ClayLayout.ContainerFluid>
                    <ClayLayout.Row>
                            {userEnrollments.map(
                                enrollment => status?
                                    <ClayLayout.Col size={4} className="d-flex flex-column"><CompletedCourse course={enrollment}></CompletedCourse> </ClayLayout.Col>:
                                    <ClayLayout.Col size={4} className="d-flex flex-column"><UncompletedCourse course={enrollment}></UncompletedCourse></ClayLayout.Col>
                            )}
                    </ClayLayout.Row>
                </ClayLayout.ContainerFluid>
            )}

            {userEnrollments && userEnrollments.length === 0 && !status && (
                <div className="empty-section-card">
                    <h3>{t('dashboard-label-inprogress-no-courses-header')}</h3>
                    <p>
                        {t('dashboard-label-inprogress-no-courses-body')}
                    </p>
                    <a href={coursesLandingPage} className="card-cta-button">
                        {t('explore-courses')}
                        <ClayIcon className="ml-2" symbol="order-arrow-right"/>
                    </a>
                </div>
            )}

            {userEnrollments && userEnrollments.length === 0 && status && (
                <div className="empty-section-card">
                    <h3>It looks like you haven’t completed any Course yet.</h3>
                    <p>
                        {t('dashboard-label-completed-no-courses-body')}
                    </p>

                    <a href={coursesLandingPage} className="card-cta-button">
                        {t('explore-courses')}
                        <ClayIcon className="ml-2" symbol="order-arrow-right"/>
                    </a>
                </div>
            )}
        </div>
    )
}

export default UserDashboardCoursesList;
