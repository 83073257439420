import {getServerUrl, oAuthRequest} from "../utils/request";
import {config} from "../utils/constants";
import {getCurrentSiteId} from "../utils/util";

export const postProgress = (assetId,assetType,assetProgress) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.progressEndPoint}`,
        method:'post',
        data:{
            assetEntryId:assetId,
            type :assetType,
            progress:assetProgress,
            scopeId:getCurrentSiteId()
        }
    });

}

export const getProgress = () =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.progressEndPoint}${getCurrentSiteId()}`,
        method:'get'
    });

}



