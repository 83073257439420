import {request} from "../utils/request";


const DeleteObjects = ()=>{

    const deleteObjRel = (id)=>{

        return request({
            url:`/o/object-admin/v1.0/object-relationships/${id}`,
            method: "delete"
        })

    }

    const deleteObj = (id)=>{

        return request({
            url:`/o/object-admin/v1.0/object-definitions/${id}`,
            method: "delete"
        })

    }

    const handleDelete = () =>{

        request({
            url :'/o/object-admin/v1.0/object-folders/by-external-reference-code/T4T14_LMS_FOLDER',
            method:'get'
        }).then(async result => {

            for (const element of result.objectFolderItems) {
                if (element.objectDefinition) {
                    const {objectDefinition} = element;
                    const {id, objectRelationships,name} = objectDefinition;

                    if (objectRelationships && objectRelationships.length > 0) {
                        for (let index =0 ; index < objectRelationships.length ; index++){
                            await deleteObjRel(objectRelationships[index].id);
                        }
                    }
                }
            }

            for (const element of result.objectFolderItems) {
                if (element.objectDefinition) {
                    const {objectDefinition} = element;
                    const {id, objectRelationships,name} = objectDefinition;
                    await deleteObj(id);
                }
            }


        })

    }


    return <button onClick={handleDelete}> clear objects</button>


}

export default DeleteObjects;
