import {getServerUrl, oAuthRequest} from "../utils/request";
import {config} from "../utils/constants";


export const loadObjectDefinitions = () =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.menuEndPoint}`,
        method:'get'
    });

}
