
import {getServerUrl, oAuthRequest, request} from "../utils/request";
import {config} from "../utils/constants";
import {getCurrentSiteId, getCurrentUserId, isSignedIn} from "../utils/util";


export const getModulesByCourseId = (courseId) =>{

    return request({
        url:`${config.courseEndpoint}/${courseId}/module?page=0`,
        method:"get"
    })

}

export const getLessonByModuleId = (moduleId) =>{

    return request({
        url:`${config.moduleEndpoint}${moduleId}/lesson?page=0`,
        method:"get"
    })

}

export const getExercisesByModuleId = (moduleId) =>{

    return request({
        url:`${config.moduleEndpoint}${moduleId}/exercise?page=0`,
        method:"get"
    })

}

export const getLessonById = (lessonId) =>{

    return request({
        url:`${config.lessonEndpoint}${lessonId}`,
        method:"get"
    })

}

export const getAttachmentsByLessonId = (lessonId) =>{

    return request({
        url:`${config.lessonEndpoint}${lessonId}/attachment?page=0`,
        method:"get"
    })

}

export const getEnrollmentsByCourseId = (courseId) => {

    let parsedId =  isNaN(parseInt(courseId)) ? -1 : parseInt(courseId);

    return request({
        url: `${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}?filter=r_courseEnrollment_c_courseId eq '${parsedId}' and r_userenrollments_userId eq '${getCurrentUserId()}'`
    })

}

export const getEnrollmentsByLearningPathId = (learningPathId) => {

    let parsedId =  isNaN(parseInt(learningPathId)) ? -1 : parseInt(learningPathId);

    return request({
        url: `${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}?filter=r_learningPathEnrollment_c_learningPathId eq '${parsedId}' and r_userenrollments_userId eq '${getCurrentUserId()}'`
    })

}

export const enrollToCourse = (courseId) => {

    return request({
        url:`${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}`,
        method: 'post',
        data:{
            "r_courseEnrollment_c_courseId": courseId,
            "r_userenrollments_userId": getCurrentUserId()
        }
    });

}

export const enrollToLearningPath = (learningPathId) => {

    return request({
        url:`${config.enrollmentEndpoint}scopes/${getCurrentSiteId()}`,
        method: 'post',
        data:{
            "r_learningPathEnrollment_c_learningPathId": learningPathId,
            "r_userenrollments_userId": getCurrentUserId()
        }
    });

}

export const getCourseByLessonId = (lessonId) => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.utilsEndPoint}lesson/${lessonId}/course`,
        method: 'get',
    });

}

export const getCourseByQuizId = (quizId) => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.utilsEndPoint}quiz/${quizId}/course`,
        method: 'get',
    });

}

export const getCourseByAssetTypeAssetId = (assetType,assetId) => {

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.utilsEndPoint}${assetType}/${assetId}/course`,
        method: 'get',
    });

}
