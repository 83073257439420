import {getSignInPath, isSignedIn} from "../utils/util";
import ClayAlert from "@clayui/alert";
import ClayButton from "@clayui/button";
import ClayLink from "@clayui/link";
import {useTranslation} from "react-i18next";


const SignIn = ({children}) => {

    const {t, i18n: {changeLanguage, language}} = useTranslation();

    return (<>
        {!isSignedIn() && (
            <ClayAlert
                actions={
                    <ClayButton.Group>
                        <ClayLink href={getSignInPath()} alert>{t('sign-in')}</ClayLink>
                    </ClayButton.Group>
                }
                displayType="info"
                title={null}
                variant="inline"
            >
                {t('sign-in-required')}
            </ClayAlert>
        )}
        {isSignedIn() && (
            <>
                {children}
            </>
        )}
    </>)

}

export default SignIn;
